const { DateTime } = require('luxon')

const timeOfDay = (time) => {
  const hour = DateTime.fromJSDate(time).hour

  if (hour >= 6 && hour < 9) {
    return 'morning'
  } else if (hour < 6 || hour >= 23) {
    return 'night'
  } else if (hour >= 18 && hour < 23) {
    return 'evening'
  }
  return 'day' // 9-18
}

module.exports = timeOfDay
