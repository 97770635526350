<template>
  <div>
    <div class="text-center my-5" v-if="!organizationName">
      <div class="spinner-border spinner-border-lg text-primary mt-10"></div>
    </div>
    <b-container v-else fluid>
      <b-row class="justify-content-center mt-5">
        <b-col xl="8" lg="10" cols="12">
          <b-alert
            v-if="envOverride"
            :show="true"
            variant="danger"
            style="border: 2px dashed red"
            class="ms-2 me-2"
          >
            Environment override active:
            <b>
              <b-link to="tester" class="text-danger">{{ envOverride }}</b-link>
            </b>
          </b-alert>

          <b-card>
            <div class="d-flex flex-column flex-md-row justify-content-between">
              <div v-if="dashboardTitle && greeting">
                <h2 class="header me-3" v-if="givenName">
                  {{
                    $t(`dashboard.greetings.${greeting}`, {
                      username: givenName
                    })
                  }}
                </h2>
                <h2 class="header me-3" v-else>
                  {{ $t('dashboard.greetings.simple') }}
                </h2>

                <p class="fs-3 mt-5">
                  {{ dashboardTitle }}
                </p>
              </div>

              <div class="logo-container" v-if="logoUrl">
                <img
                  class="headerOrgLogo"
                  :class="logoImageIsSquare ? 'headerOrgLogoSquare' : ''"
                  :src="logoUrl"
                  @load="logoImageLoaded"
                />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row
        v-if="!surveysLoading && !surveysError && publishedSurveys.length"
        class="surveys-to-fill-in justify-content-center mt-5"
      >
        <b-col xl="8" lg="10" cols="12">
          <b-card>
            <SurveysToFillIn :publishedSurveys="publishedSurveys" />
          </b-card>
        </b-col>
      </b-row>

      <b-row class="surveys-results justify-content-center mt-5">
        <b-col xl="8" lg="10" cols="12">
          <div class="text-center my-5" v-if="resultsLoading">
            <div
              class="spinner-border spinner-border-lg text-primary mt-10"
            ></div>
          </div>

          <b-card-group deck>
            <ResultsSurveys />
          </b-card-group>
        </b-col>
      </b-row>

      <b-row
        class="settings mt-5 justify-content-center"
        v-if="!resultsLoading"
      >
        <b-col xl="8" lg="10" cols="12">
          <div class="card">
            <div class="card-body">
              <h3>{{ $t('dashboard.settings.title') }}</h3>

              <p>{{ $t('dashboard.settings.body') }}</p>

              <div class="my-5">
                <b-button
                  variant="secondary"
                  class="btn btn-sm btn-round me-3"
                  @click="
                    $router.push({
                      name: 'User',
                      params: { comesFromStart: false }
                    })
                  "
                >
                  {{ $t('dashboard.settings.title') }}
                </b-button>

                <b-button
                  variant="secondary"
                  class="btn btn-sm btn-round ml-5"
                  @click="
                    $router.push({
                      name: 'Integrations'
                    })
                  "
                >
                  {{ $t('dashboard.integrations.title') }}
                </b-button>
              </div>

              <b-row class="mt-10">
                <span class="me-5">
                  {{
                    $t('dashboard.settings.logout.loggedin', {
                      username: fullName
                    })
                  }}
                </span>
              </b-row>
              <b-row>
                <div class="mt-5">
                  <b-button
                    variant="outline-dark"
                    class="btn btn-sm btn-round btn-outline btn-outline-secondary btn-active-light-dark"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-logout"
                  >
                    {{ $t('dashboard.settings.logout.button.title') }}
                  </b-button>
                </div>

                <PulsModal
                  id="modal-logout"
                  :title="$t('dashboard.settings.logout.confirmation.title')"
                  :ok-title="$t('dashboard.settings.logout.confirmation.yes')"
                  :cancel-title="
                    $t('dashboard.settings.logout.confirmation.cancel')
                  "
                  :on-ok="logOutUser"
                >
                  <template #modal-body>
                    <p class="my-4">
                      {{
                        $t('dashboard.settings.logout.confirmation.areyousure')
                      }}
                    </p>
                  </template>
                </PulsModal>
              </b-row>
            </div>
          </div>

          <IEText />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import IEText from './IEText'
import { logout } from '../services/AwsAmplifyService'
import { PulsModal } from '@puls-solutions/puls-ui-components'
import timeOfDay from '../helpers/time-helper'

export default {
  name: 'Overview',
  data() {
    return {
      logoImageIsSquare: false
    }
  },
  mounted() {
    this.setLocale()
    if (
      this.$route.query.u &&
      this.$store.getters.loggedInUser &&
      this.usernameInQueryDifferentFromLoggedInUser
    ) {
      this.redirectToStart()
    } else if (
      this.$route.query.o &&
      this.organizationName &&
      this.organizationName !== this.$route.query.o
    ) {
      this.redirectToStart()
    } else if (localStorage.temporaryRedirect) {
      const path = localStorage.temporaryRedirect
      window.location = path
      localStorage.removeItem('temporaryRedirect')
    } else {
      this.loadSurveys()
    }
  },
  computed: {
    envOverride() {
      return window.localStorage.envOverride
    },
    loggedInUser() {
      return this.$store.getters.loggedInUser
    },
    usernameInQueryDifferentFromLoggedInUser() {
      if (this.$store.getters.loggedInUser && this.$route.query.u) {
        const username = this.$route.query.u
        const usernameIsEmail = username.includes('@')
        if (usernameIsEmail) {
          // // eslint-disable-next-line no-console
          // console.log(username)
          // // eslint-disable-next-line no-console
          // console.log(
          //   `redirect: ${this.userEmail !== username} - ${username} vs ${
          //     this.userEmail
          //   }`
          // )
          return this.userEmail !== username
        } else {
          return this.$store.getters.loggedInUser.username !== username
        }
      } else {
        return false
      }
    },
    userEmail() {
      return this.$store.getters.loggedInUser?.signInUserSession?.idToken
        .payload.email
    },
    surveysLoading() {
      return this.$store.state.surveys.loading
    },
    surveysError() {
      return this.$store.state.surveys.error
    },
    resultsLoading() {
      return this.$store.getters.getResultsLoading
    },
    dashboardTitle() {
      return this.$t('dashboard.introHeadline', {
        productTitle: this.productTitle,
        organizationName: this.$store.getters.userInfo.organizationFullName
      })
    },
    fullName() {
      return this.loggedInUser
        ? this.fullNameForUser(this.loggedInUser)
        : 'no name'
    },
    givenName() {
      return this.$store.getters.givenName
    },
    productTitle() {
      return this.$productTitle(this)
    },
    logoUrl() {
      // return 'https://www.puls-solutions.com/public/logos/demo.png'
      return this.$store.getters.logoUrl
    },
    publishedSurveys() {
      return this.$store.getters.getPublishedSurveys || []
    },
    greeting() {
      return timeOfDay(new Date())
    },
    organizationName() {
      return this.$store.getters.userInfo.organizationName
    }
  },
  methods: {
    logoImageLoaded(data) {
      const image = document.querySelector('img.headerOrgLogo')
      if (image) {
        const aspectRatio = image.naturalWidth / image.naturalHeight
        this.logoImageIsSquare = aspectRatio === 1.0
      }
    },
    loadSurveys() {
      this.$store.dispatch('loadSurveys', {
        organizationName: this.organizationName
      })
    },
    afterLogout() {
      this.$store.commit('setLoggedInUser', null)
      this.$store.commit('setHasToken', false)
      window.localStorage.removeItem('orgData')
      this.$router.push('/loggedout')
    },
    logOutUser() {
      return logout()
        .then(() => this.afterLogout())
        .catch(() => this.afterLogout())
    },
    redirectToStart() {
      this.$router.push({ path: 'start', query: this.$route.query })
    },
    setLocale() {
      const attributes = this.$store.getters.loggedInUser
        ? this.$store.getters.loggedInUser.signInUserSession.idToken.payload
        : null
      if (attributes && attributes.locale) {
        const locale = attributes.locale.split('-')[0]
        window.localStorage.setItem('selectedLocale', locale)
        this.$i18n.locale = locale
      }
    },
    fullNameForUser(user) {
      const userIfo = user.signInUserSession.idToken.payload

      if (userIfo.given_name && userIfo.family_name) {
        return `${userIfo.given_name} ${userIfo.family_name}`
      } else if (userIfo.given_name) {
        return userIfo.given_name
      }
      return userIfo.email
    }
  },
  components: {
    SurveysToFillIn: () => import('@/components/SurveysToFillIn'),
    ResultsSurveys: () => import('@/components/ResultsSurveys'),
    IEText: IEText,
    PulsModal
  }
}
</script>

<style scoped lang="scss">
img.headerOrgLogo {
  background-color: rgba(0, 0, 0, 0);
  object-fit: contain;
  width: 128px;
  height: 128px;
}

img.headerOrgLogoSquare {
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.content {
  padding: 0;
}

@media screen and (max-width: 1090px) {
  .headerOrgLogo {
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 992px) {
  .headerOrgLogo {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
    margin-top: 34px;
    margin-bottom: 20px;
  }
}
</style>
